@import url("https://fonts.cdnfonts.com/css/optima-nova-lt-pro?styles=77580");
@import url("https://fonts.cdnfonts.com/css/optima-nova-lt-pro?styles=77596,77580");
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@600&display=swap");
.rs-anim-fade {
  z-index: 9000 !important;
}
.break-avoid {
  page-break-inside: avoid;
}

.break-before {
  margin-top: 2em !important; /* Adjust the value as needed to add more space */
  page-break-before: always !important;
}

.break-after {
  margin-bottom: 2em !important; /* Adjust the value as needed to add more space */
  page-break-after: always !important;
  /* page-break-before: always !important; */
}

.nova_font_bold {
  font-family: "Optima Nova LT Pro", sans-serif !important;
  font-weight: 700;
  /* Specifies bold */
}

.nova_font_normal {
  font-family: "Optima Nova LT Pro", sans-serif !important;
  font-weight: 400;
  /* Specifies normal */
}

.dosis_font {
  font-family: "Dosis", sans-serif !important;
}

.noto_font {
  font-family: "Noto Sans", sans-serif !important;
  font-weight: 700;
}

.test {
  border: 2px solid red !important;
}

.border_2 {
  border: 1px solid;
}

.text_color {
  color: #bd9a8a !important;
}

.text_color2 {
  color: #4e5663 !important;
}

.fs_24px {
  font-size: 1.5rem;
}

.fs_20px {
  font-size: 20px !important;
}
.fs_18px {
  font-size: 18px !important;
}
.fs_16px {
  font-size: 16px !important;
}

.fs_14px {
  font-size: 14px !important;
}

.fs_12px {
  font-size: 12px !important;
}

.fs_10px {
  font-size: 10px !important;
}

.btn_bg {
  background-color: #c6a08f !important;
  /* background-image: linear-gradient(to right, #F96E97 , #FE7B2C); */
  color: white;
}

.btn_bg_yellow {
  background-color: yellow !important;
  /* background-image: linear-gradient(to right, #F96E97 , #FE7B2C); */
  color: black !important;
}

.btn_bg_red {
  background-color: red !important;
  /* background-image: linear-gradient(to right, #F96E97 , #FE7B2C); */
  color: black !important;
}

.btn_bg_secondery {
  background-color: #4e5663 !important;
  color: white !important;
}

.btn_bg_light {
  background-color: #ffff !important;
  color: black !important;
}

.btn_bg_ter {
  background-color: #3e4755 !important;
  color: white !important;
}

.white_space {
  white-space: nowrap !important;
}

.icon_grad {
  font-size: "40px";
  background-image: linear-gradient(to bottom #f98b9b, #fda778);
}

.same-size-button {
  width: 120px;
  /* Adjust the width as needed */
  height: 40px;
  /* Adjust the height as needed */
}

.border_left {
  border-left: 2px solid #716879;
  padding-left: 10px;
}

.border_left_2 {
  border-left: 1px solid #716879;
}

.border_right {
  border-right: 1px solid #716879;
}

.line {
  display: inline-block;
  width: 1px;
  height: 100%;
  background-color: white;
  margin-right: 5px;
  /* Adjust this value for spacing */
}

.selected {
  font-weight: 800 !important;
  color: yellow !important;
  background-color: #4e5663 !important;
}
.energetic-highlight {
  margin-top: 15px;
  background-color: #ffff99; /* Highlight color, you can change it to your preference */
  transform-origin: center top; /* Set the transform origin to the center */
  transition:
    background-color 0.3s ease,
    transform 0.3s ease; /* Smooth transition effect for background-color and scale */
  transform: scale(1.2); /* Zoom in a bit more on hover */
  box-shadow: 0 0 30px rgba(255, 255, 0, 0.7); /* Yellow drop shadow on hover */
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #3e4755 !important;
  color: #fff;
}

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* @media print {
  .print-page-container {
    position: relative;
  }

  .page-header,
  .page-footer {
    position: fixed;
    left: 0;
    right: 0;
    height: 50px;
    background-color: #f0f0f0;
  }

  .page-header {
    top: 0;
  }

  .page-footer {
    bottom: 0;
    border-top: 2px solid black;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
  }
} */
/* @media print {
  .page {
    page-break-inside: avoid !important;
  }
} */
@media print {
  .page-break {
    page-break-after: always !important;
  }
  /* .page-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px; 
    background-color: #f5f5f5;
  } */
}

.check-group {
  background: #fff;
  max-width: 13rem;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 10, 0.2);
  counter-reset: total;
  counter-reset: checked;
}

.check-group > * + * {
  margin-top: 0.75rem;
}

.check-group .checkbox {
  counter-increment: total;
}

.check-group input[type="checkbox"]:checked {
  counter-increment: checked;
}

.check-group__result {
  font-weight: bold;
  padding-top: 0.75rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.check-group__result:after {
  content: counter(checked) " / " counter(total);
  padding-left: 1ch;
}

.checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.checkbox__input {
  position: absolute;
  width: 1.375em;
  height: 1.375em;
  opacity: 0;
  cursor: pointer;
}

.checkbox__input:checked + .checkbox__icon .tick {
  stroke-dashoffset: 0;
}

.checkbox__icon {
  width: 1.375em;
  height: 1.375em;
  flex-shrink: 0;
  overflow: visible;
}

.checkbox__icon .tick {
  stroke-dasharray: 20px;
  stroke-dashoffset: 20px;
  transition: stroke-dashoffset 0.2s ease-out;
}

.checkbox__label {
  margin-left: 0.5em;
}
